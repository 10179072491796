/* Base styles */
.services-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  color: #fff;
  background: none;
  background-size: cover;
  background-image: linear-gradient(to bottom, #E1F2F7, #e6e6e6);
  animation: colorChange 5s infinite alternate;
  background-size: 100% auto; 
}
@keyframes colorChange {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}

.services-heading {
  text-align: center;
  color: #0F2557;
  font-size: 3.5rem;
  margin-bottom: 2rem;
}

.content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
}

.service {
  width: 100%;
  max-width: 500px;
  background-color: #0F2557;
  backdrop-filter: blur(10px);
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0.5, 0.5, 0.5, 0.8);
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.service:hover {
  background: #001730;
  transform: scale(1);
}

.service img {
  width: 100%;
  height: 250px; /* Set a fixed height for images */
  object-fit: cover;
  transition: transform 0.3s ease;
}

.service:hover img {
  transform: scale(1.05);
}

.service-content {
  padding: 1.5rem;
  background-color: transparent;
  text-align: center;
}

.service-content h2 {
  color: #FEE715;
  font-size: 1.8rem;
  margin-bottom: 1rem;
}

.service-content p {
  color: #fff;
  font-size: 1rem;
  line-height: 1.6;
  transition: color 0.3s ease;
}

/* Responsive Design */

/* For medium screens */
@media (max-width: 1024px) {
  .service {
    max-width: 45%;
  }
  
  .services-heading {
    font-size: 2.2rem;
  }

  .service-content h2 {
    font-size: 1.6rem;
  }

  .service-content p {
    font-size: 0.95rem;
  }
}

/* For small screens */
@media (max-width: 768px) {
  .content {
    gap: 1.5rem;
  }

  .service {
    max-width: 90%;
  }

  .services-heading {
    font-size: 2rem;
  }

  .service-content h2 {
    font-size: 1.5rem;
  }

  .service-content p {
    font-size: 0.9rem;
  }
}

/* For extra small screens */
@media (max-width: 480px) {
  .services-container {
    padding: 1rem;
  }

  .services-heading {
    font-size: 1.8rem;
  }

  .service {
    max-width: 90%;
  }

  .service img {
    height: 200px; /* Adjust image height for smaller screens */
  }

  .service-content {
    padding: 0.5rem;
  }

  .service-content h2 {
    font-size: 1.3rem;
  }

  .service-content p {
    font-size: 0.85rem;
  }
}
