.policy-container {
    padding: 2rem;
    background-color: #f4f4f4;
    color: #333;
  }
  
  .policy-container h1 {
    color: #0F2557;
    margin-bottom: 1rem;
  }
  
  .policy-container h2,
  .policy-container h3 {
    color: #0F2557;
    margin-top: 1.5rem;
    margin-bottom: 1rem;
  }
  
  .policy-container p,
  .policy-container ul {
    font-size: 1rem;
    line-height: 1.6;
  }
  
  .policy-container ul {
    margin-bottom: 1rem;
  }
  
  .policy-container ul li {
    margin-bottom: 0.5rem;
  }
  
  .policy-container a {
    color: #007bff;
    text-decoration: none;
  }
  
  .policy-container a:hover {
    text-decoration: underline;
  }
  