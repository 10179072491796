/* Base styles */
.products-page {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #fff; /* Light text color for better contrast */
  background-size: cover;
  /* backdrop-filter: blur(10px); */
  padding: 2rem;
  background-image: linear-gradient(to bottom, #e6e6e6, #E1F2F7);
  animation: colorChange 5s infinite alternate;
  background-size: 100% auto; 
}

.content-wrapper {
  width: 100%;
  max-width: 1200px;
  padding: 2rem;
  background-color: #0F2557; /* Transparent background */
  border-radius: 10px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3); /* Softer box shadow */
  text-align: center;
  backdrop-filter: blur(10px);
  flex-wrap: wrap;
  margin-bottom: 40px;
}

.main-heading {
  font-size: 3.5rem;
  color: #0F2557;
}

.product-heading {
  font-size: 3rem;
  color: #ffdd57;
  margin-bottom: 5px;
}

.product-subheading {
  font-size: 1.5rem;
  margin-bottom: 20px;
}

.upper-mid {
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  min-width: 300px;
  justify-content: center;
  /* margin-right: 20px; */
}

.product-section {
  padding: 3rem;
}

.product-section h2{
  color: #FB8122;
}
.product-overview {
  padding: 3rem;
}
.product-overview h2{
  color: #FB8122;
}

.visruti-image {
  width: 100%;
  max-width: 700px;
  margin: 0 auto;
}

.right-content {
  flex: 1;
  min-width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.facts-list {
  list-style: none;
  padding: 0;
  font-size: 1.1rem;
  text-align: left;
  margin: 0 auto;
  max-width: 600px;
}

.product-overview img.visruti-image {
  width: 100%;
  max-width: 400px;
  margin: 20px 0;
}

.additional-images {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin: 20px 0;
}

.additional-images img {
  width: 100%;
  max-width: 900px;
  border-radius: 10px;
}

.product-description {
  max-width: 550px;
  margin: 0 auto;
  font-size: 1.0rem;
  line-height: 1.6;
  padding: 0 10px;
}

.product-description1 {
  max-width: 850px;
  margin: 0 auto;
  font-size: 1.1rem;
  line-height: 1.6;
  padding: 0 20px;
}

.video-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
  padding-top: 20px; /* Add some padding at the top */
}

.video-wrapper {
  position: relative;
  width: 48%;
  padding-top: 27%; /* 16:9 Aspect Ratio */
  overflow: hidden;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.video-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
  border-radius: 15px;
}

/* Responsive styles */
@media (max-width: 1000px) {
  .video-wrapper {
    width: 100%;
    padding-top: 56.25%; /* 16:9 Aspect Ratio for single column on smaller screens */
  }
}

.differentiators-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.differentiator {
  background: #ffffff;
  color: #000;
  padding: 20px;
  border-radius: 10px;
  width: 100%;
  max-width: 130px;
  text-align: center;
  transition: transform 0.3s;
}

.differentiator img {
  width: 50px;
  margin-bottom: 10px;
}

.differentiator:hover {
  transform: scale(1.05);
}

/* Responsive Design */

/* For medium screens */
@media (max-width: 1024px) {
  .main-heading {
    font-size: 3rem;
  }

  .product-heading {
    font-size: 2.5rem;
  }

  .product-subheading {
    font-size: 1.2rem;
  }

  .content-wrapper {
    max-width: 90%;
  }

  .facts-list {
    font-size: 1rem;
  }

  .additional-images img {
    max-width: 700px;
  }

  .product-description,
  .product-description1 {
    padding: 0 15px;
  }

  .differentiator {
    max-width: 180px;
  }
}

/* For small screens */
@media (max-width: 768px) {
  .content-wrapper {
    padding: 1.5rem;
  }

  .main-heading {
    font-size: 2.5rem;
  }

  .product-heading {
    font-size: 2rem;
  }

  .product-subheading {
    font-size: 1rem;
  }

  .facts-list {
    font-size: 0.9rem;
  }

  .product-description,
  .product-description1 {
    padding: 0 10px;
  }

  .upper-mid {
    flex-direction: column;
    margin-right: 0;
  }

  .additional-images {
    flex-direction: column;
    align-items: center;
    max-width: 300px;
  }

  .differentiators-grid {
    flex-direction: column;
  }

  .differentiator {
    max-width: 100%;
    width: auto;
  }
}

/* For extra small screens */
@media (max-width: 480px) {
  .main-heading {
    font-size: 2rem;
  }

  .product-heading {
    font-size: 1.5rem;
  }

  .product-subheading {
    margin-bottom: 5px;
    font-size: 0.9rem;
  }

  .facts-list {
    font-size: 0.8rem;
  }

  .product-section {
    padding: 0.5rem;
  }

  .product-overview {
    padding: 0.5rem;
    margin-bottom: 10px;
  }

  .product-description,
  .product-description1 {
    font-size: 0.9rem;
    padding: 0.1rem;
  }

  .video-link {
    padding: 8px 16px;
  }

  .differentiator {
    padding: 1px;
  }

  .differentiator img {
    width: 50px;
    padding: 0.2rem;
  }
}
