/* ReturnRefundPolicy.css */

/* Base Styling */
.policy-container {
  margin: 0 auto;
  padding: 20px;
  max-width: 800px;
  font-family: 'Arial', sans-serif;
  line-height: 1.6;
  color: #333;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

/* Headings */
.policy-container h1 {
  font-size: 2em;
  margin-bottom: 20px;
  color: #0056b3;
  text-align: center;
}

.policy-container h2 {
  font-size: 1.75em;
  margin-top: 30px;
  margin-bottom: 15px;
  color: #444;
}

.policy-container p {
  margin-bottom: 15px;
  text-align: justify;
  line-height: 1.8;
  color: #555;
}

/* Responsive Design */
@media (max-width: 768px) {
  .policy-container {
    padding: 15px;
  }
  
  .policy-container h1 {
    font-size: 1.75em;
  }
  
  .policy-container h2 {
    font-size: 1.5em;
  }
  
  .policy-container p {
    margin-bottom: 10px;
    font-size: 1em;
  }
}

@media (max-width: 480px) {
  .policy-container {
    padding: 10px;
  }
  
  .policy-container h1 {
    font-size: 1.5em;
  }
  
  .policy-container h2 {
    font-size: 1.25em;
  }
  
  .policy-container p {
    font-size: 0.9em;
  }
}
