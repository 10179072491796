/* Career Section */
.career-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-image: linear-gradient(to bottom, #E1F2F7, #e6e6e6);
  animation: colorChange 5s infinite alternate;
  background-size: 150% auto; 
  padding: 2rem;
}

.career-box {
  max-width: 80%;
  background-color: #0F2557;
  padding: 2rem;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0.5, 0.5, 0.5, 0.8);
  text-align: center;
  color: white;
}

.career-container-heading {
  font-size: 3.5rem;
  margin-bottom: 2rem;
  font-weight: bold;
  color: #0F2557;
}

.career-heading h1 {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
  color: #FDEE30;
}

.career-heading p {
  font-size: 1.4rem;
  line-height: 1.6;
  margin-bottom: 2rem;
}

.about-us-section {
  max-width: 80%;
  margin: 0 auto;
}

.about-us-section h2 {
  font-size: 2.8rem;
  font-weight: bold;
  margin-bottom: 1.5rem;
  color: #FDEE30;
}

.about-us-section p {
  font-size: 1.2rem;
  line-height: 1.6;
  margin-bottom: 2rem;
}

.apply-button {
  padding: 1.2rem 2.5rem;
  font-size: 1.4rem;
  font-weight: bold;
  color: #fff;
  background-color: #EF0D50;
  border: none;
  border-radius: 25px;
  text-decoration: none;
  cursor: pointer;
  transition: transform 0.3s;
  position: relative;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.apply-button:hover {
  transform: translateY(-3px);
  background-color: #0056b3;
}

.wave-image {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: -1;
}

/* Responsive Design */
@media (max-width: 1024px) {
  .career-box {
    padding: 1.5rem;
  }

  .career-container-heading {
    font-size: 2.2rem;
  }

  .career-heading h1 {
    font-size: 2.2rem;
  }

  .career-heading p {
    font-size: 1.2rem;
  }

  .about-us-section h2 {
    font-size: 2.5rem;
  }

  .about-us-section p {
    font-size: 1.1rem;
  }

  .apply-button {
    padding: 1rem 2rem;
    font-size: 1.2rem;
  }
}

@media (max-width: 768px) {
  .career-box {
    max-width: 90%;
  }

  .career-container-heading {
    font-size: 2rem;
  }

  .career-heading h1 {
    font-size: 2rem;
  }

  .career-heading p {
    font-size: 1.2rem;
  }

  .about-us-section h2 {
    font-size: 2rem;
  }

  .about-us-section p {
    font-size: 1.1rem;
  }

  .apply-button {
    padding: 1rem 2rem;
    font-size: 1.1rem;
  }
}

@media (max-width: 480px) {
  .career-box {
    padding: 1rem;
  }

  .career-container-heading {
    font-size: 1.8rem;
  }

  .career-heading h1 {
    font-size: 1.8rem;
  }

  .career-heading p {
    font-size: 1rem;
  }

  .about-us-section h2 {
    font-size: 1.8rem;
  }

  .about-us-section p {
    font-size: 1rem;
  }

  .apply-button {
    padding: 0.8rem 1.5rem;
    font-size: 1rem;
  }
}
