.client-container {
  padding: 2rem;
  text-align: center;
  background-image: linear-gradient(to bottom, #e6e6e6, #e1f2f7);
  animation: colorChange 5s infinite alternate;
  background-size: 150% auto;
}

.client-container h1 {
  font-size: 3.5rem;
  margin-bottom: 2rem;
  font-weight: bold;
  color: #0f2557;
}

.client-card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  text-align: left;
  gap: 2rem;
}

.client-card {
  background-color: #0f2557;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  max-width: 400px; /* Updated to max-width */
  width: 100%;
  padding: 8px;
  color: white;
  position: relative;
  transition: all 0.3s;
}

.client-card.expanded {
  max-width: 600px; /* Updated to max-width */
}

.client-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.client-image-container {
  width: 100%;
  height: 250px;
  overflow: hidden;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.client-details {
  padding: 1.5rem;
}

.client-details h2 {
  font-size: 1.5rem;
  color: #ffffff;
  margin-bottom: 1rem;
}

.client-details p {
  font-size: 1rem;
  color: #ccc;
}

.more-button {
  background-color: #e1f2f7;
  border: none;
  color: #0f2557;
  padding: 0.5rem 1rem;
  cursor: pointer;
  font-size: 1rem;
  margin-top: 1rem;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.more-button:hover {
  background-color: #cfdfe6;
}

.more-content {
  margin-top: 1rem;
  text-align: left;
}

.more-content p {
  margin-bottom: 0.5rem;
  font-size: 0.9rem;
}

.more-content h3 {
  font-size: 1.2rem;
  margin-top: 1rem;
  font-weight: bold;
}

@media (max-width: 1024px) {
  .client-container {
    padding: 1.5rem;
  }

  .client-container h1 {
    font-size: 2.2rem;
  }

  .client-details h2 {
    font-size: 1.4rem;
  }

  .client-details p {
    font-size: 0.9rem;
  }

  .more-content p {
    font-size: 0.8rem;
  }

  .more-content h3 {
    font-size: 1.1rem;
  }
}

@media (max-width: 768px) {
  .client-card {
    max-width: 100%;
  }

  .client-container h1 {
    font-size: 2rem;
  }

  .client-details h2 {
    font-size: 1.3rem;
  }

  .client-details p {
    font-size: 0.85rem;
  }

  .more-content p {
    font-size: 0.75rem;
  }

  .more-content h3 {
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .client-container h1 {
    font-size: 1.8rem;
  }

  .client-details h2 {
    font-size: 1.2rem;
  }

  .client-details p {
    font-size: 0.8rem;
  }

  .more-content p {
    font-size: 0.7rem;
  }

  .more-content h3 {
    font-size: 0.9rem;
  }
}
