.home-container {
  display: flex;
  align-items: center;
  height: 100vh;
  overflow: hidden;
  background-image: linear-gradient(to bottom, #E1F2F7, #e6e6e6);
  background-image: url('../pages/background_Image/home.jpg');
  background-size: cover;
  background-position: center;
}

.home-content {
  color: #fff;
  position: relative;
  z-index: 1;
  max-width: 900px;
  padding: 0 3rem;
}

.home-content h1,
.home-content h2 {
  font-size: 3rem;
  margin: 0;
  color: #121313;
}

.tech {
  font-size: 50px;
  color: #C13F17;
}

.us {
  font-size: 60px;
  color: #005BAB;
}

.separator {
  padding-left: 25px;
  font-size: 70px;
}

.home-content p {
  color: #373737;
  font-size: 1.1rem;
  margin: 1rem 0;
}

.home-buttons {
  display: flex;
  gap: 1rem; /* Added gap between buttons */
  flex-wrap: wrap; /* Wrap buttons on smaller screens */
}

.fine {
  display: flex;
  text-align: center;
  align-items: center;
}

@keyframes pulse-border {
  0% {
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.7), 0 0 15px rgba(0, 123, 255, 0.2), 0 0 30px rgba(0, 123, 255, 0.2), 0 0 50px rgba(0, 123, 255, 0.1);
  }
  50% {
    box-shadow: 0 0 10px rgba(0, 123, 255, 0.5), 0 0 20px rgba(0, 123, 255, 0.3), 0 0 40px rgba(0, 123, 255, 0.3), 0 0 60px rgba(0, 123, 255, 0.2);
  }
  100% {
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.3), 0 0 15px rgba(0, 123, 255, 0.2), 0 0 30px rgba(0, 123, 255, 0.2), 0 0 50px rgba(0, 123, 255, 0.1);
  }
}

.home-link.animate-border {
  position: relative;
  z-index: 1;
}

.home-link.animate-border::before {
  content: '';
  position: absolute;
  top: -3px;
  left: -3px;
  right: -3px;
  bottom: -3px;
  border-radius: 8px;
  background: linear-gradient(45deg, rgba(0, 123, 255, 1), rgba(0, 193, 255, 1));
  z-index: -1;
  animation: pulse-border 2s infinite;
}


.home-link {
  display: inline-block;
  padding: 0.8rem 1.5rem;
  color: #fff;
  background-color: #EA445A;
  border-radius: 8px;
  text-decoration: none;
  font-weight: bold;
  font-size: 1.0rem;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0.5, 0.5, 0.5, 0.8);
  transition: background-color 0.3s, transform 0.3s;
}

.home-link:hover {
  background-color: #0056b3;
  transform: scale(1.05);
}

@media (max-width: 1024px) {
  .home-content {
    padding: 0 2rem;
  }

  .home-content h1,
  .home-content h2 {
    font-size: 2.5rem;
  }

  .tech {
    font-size: 3.5rem;
    color: #C13F17;
  }
  
  .us {
    font-size: 4.5rem;
    color: #005BAB;
  }
  .separator {
    padding-left: 30px;
    font-size: 5.5rem;
  }
  .home-content p {
    font-size: 1.2rem;
  }

  .home-link {
    padding: 0.9rem 2.2rem;
    font-size: 1.1rem;
  }
}

@media (max-width: 768px) {
  .home-content {
    padding: 0 1.5rem;
  }

  .home-content h1,
  .home-content h2 {
    font-size: 2rem;
  }

  .tech {
    font-size: 2.5rem;
    color: #C13F17;
  }
  
  .us {
    font-size: 2.5rem;
    color: #005BAB;
  }
  .separator {
    padding-left: 15px;
    font-size: 2.5rem;
  }

  .home-content p {
    font-size: 1.1rem;
  }

  .home-link {
    padding: 0.8rem 2rem;
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .home-content {
    padding: 0 1rem;
  }

  .tech {
    font-size: 2.0rem;
    color: #C13F17;
  }
  
  .us {
    font-size: 2.0rem;
    color: #005BAB;
  }
  .separator {
    padding-left: 10px;
    font-size: 2.5rem;
  }

  .home-content h1,
  .home-content h2 {
    font-size: 1.5rem;
  }

  .home-content p {
    font-size: 0.8rem;
  }

  .home-link {
    padding: 0.4rem 1.0rem;
    font-size: 0.9rem;
  }
}
