/* NavBar Container */
.navBar {
  display: flex;
  width: 100%;
  box-sizing: border-box;
  position: fixed;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  top: 0;
  background-color: #FFFFFF;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.4);
  z-index: 2;
}

.logo {
  display: flex;
  align-items: center;
  text-decoration: none;
}

.logo img {
  height: 40px;
  margin-right: 0.5rem;
  transition: transform 0.3s;
}

.logo h2 {
  /* color: #ffffff; */
  color: #192532;
  font-size: 1.2rem;
  font-weight: bold;
  margin: 0;
  transition: transform 0.3s;
}

.logo h2:hover {
  transform: scale(1.1);
}

.logo:hover img {
  transform: scale(1.1);
}

.components {
  display: flex;
  gap: 2rem; /* Consistent gap */
}

.components a {
  /* color: #ffffff; */
  color: #013A74;
  text-decoration: none;
  font-size: 1rem;
  /* font-weight: bold; */
  transition: color 0.3s, transform 0.3s;
  position: relative;
}

.components a:hover {
  color: #101820;
  /* font-weight: bold; */
  transform: translateY(-3px); /* Improved hover effect */
}

.components a::after {
  content: '';
  display: block;
  width: 0;
  height: 2px;
  background: #3498db;
  transition: width 0.3s;
  position: absolute;
  left: 0;
  bottom: -4px;
}

.components a:hover::after {
  width: 100%;
}

/* Responsive Design */
@media (max-width: 768px) {
  .components {
    display: none;
    flex-direction: column;
    gap: 2.5rem; 
    background: rgba(0, 0, 0, 0.8); 
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    width: 100%;
    text-align: center;
    position: fixed;
    top: 90px;
    left: 0;
    padding: 2rem 0;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
    opacity: 0; /* Hide initially */
    transition: opacity 0.3s ease; /* Smooth transition */
    z-index: 1;
  }

  .navBar {
    flex-direction: column;
  }

  .logo {
    margin-bottom: 0.1rem;
  }
  .logo h2{
    font-size: 1.5rem;
  }

  .components.open {
    display: flex;
    opacity: 1; /* Show when open */
  }

  .components a {
    color: #ffffff; /* Ensure text is white */
  }
}

.menu-toggle {
  display: none;
}

.menu-toggle div {
  width: 25px;
  height: 3px;
  background-color: #192532; /* White color for better visibility */
  margin: 5px 0;
  transition: 0.4s;
}

.menu-toggle.open div:nth-child(1) {
  transform: rotate(-45deg) translate(-5px, 6px);
}

.menu-toggle.open div:nth-child(2) {
  opacity: 0;
}

.menu-toggle.open div:nth-child(3) {
  transform: rotate(45deg) translate(-5px, -6px);
}

@media (max-width: 768px) {
  .menu-toggle {
    display: block;
    position: fixed;
    top: 20px;
    right: 20px;
    z-index: 3; /* Ensure above other elements */
  }
}

