.footer {
  padding: 2rem;
  background: none;
  text-align: center;
  background-color: #0F2557;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
}

.footer-content {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
}

.footer-section {
  flex: 1;
  padding: 0 1rem;
  margin: 1rem 0;
}

.footer-section h3 {
  color: #FA255E;
  font-weight: bold;
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.footer-section p {
  color: #ffffff;
  font-size: 1rem;
  line-height: 1.6;
}

.footer-section ul {
  list-style: none;
  padding: 0;
}


.footer-section li {
  margin-bottom: 0.5rem;
}

.footer-section a {
  color: #ffffff;
  text-decoration: none;
  transition: color 0.3s ease;
}

.footer-section a:hover {
  color: #FA255E;
}

.social-media {
  /* display: flex; */
  justify-content: center;
  align-items: center;
}

.social-icon {
  margin: 0 10px;
  color: #ffffff;
  font-size: 1.5rem;
  transition: color 0.3s ease;
}

.social-icon:hover {
  color: #FA255E;
  text-decoration: none;
}

.trade-mark{
  font-weight: bold;
  color: #ffffff;
  /* text-align: left; */
  justify-content: center;
  align-items: center;
}

.trade-mark p {
  font-size: 20px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .footer-content {
    flex-direction: column;
    align-items: center;
  }

  .footer-section {
    margin-bottom: 2rem;
  }
}
