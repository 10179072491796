/* General styling */
.contact-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  color: #fff;
  /* position: relative; */
  background-image: linear-gradient(to bottom, #e6e6e6, #E1F2F7);
  animation: colorChange 5s infinite alternate;
  background-size: 150% auto; 
}

.contact-content {
  max-width: 800px;
  width: 100%;
  padding: 2rem;
  background-color: #0F2557;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0.5, 0.5, 0.5, 0.8);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.contact-container-heading{
  font-size: 3.5rem;
  margin-bottom: 2rem;
  font-weight: bold;
  color: #0F2557;
}

.contact-info,
.contact-form {
  flex: 1 1 50%;
  padding: 20px;
  color: #fff;
  max-width: 400px;
}

.contact-info h2,
.contact-form h2 {
  color: #FDEE30;
  margin-bottom: 1rem;
}

.input-group {
  margin-bottom: 20px;
  /* color: black; */
}

.input-group label {
  display: block;
  /* color: black; */
  margin-bottom: 5px;
}

.input-group input,
.input-group textarea {
  width: 100%;
  /* color: black; */
  padding: 10px;
  border-radius: 5px;
  border: none;
  color: #000;
}

.input-group textarea {
  resize: none;
  min-height: 150px;
  font-size: 16px;
  line-height: 1.6;
  padding: 12px;
}

.button-group {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

button {
  padding: 10px 20px;
  background-color: #EF0D50;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0.5, 0.5, 0.5, 0.8);
  transition: background-color 0.3s;
}

button:hover {
  background-color: #c40b42;
}

button:active {
  transform: scale(0.95);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.contact-form {
  padding: 20px;
  color: #fff;
  display: flex;
  flex-direction: column;
}

.contact-form h2 {
  margin-bottom: 1rem;
}

.contact-form label {
  display: block;
  margin-bottom: 5px;
}

.contact-form textarea {
  width: 100%;
  min-height: 100px;
  padding: 12px;
  border-radius: 5px;
  margin-bottom: 20px;
  border: none;
  background-color: rgba(255, 255, 255, 0.8);
  color: #000;
  resize: none;
  font-size: 16px;
  line-height: 1.6;
}

/* Media queries */
@media (max-width: 992px) {
  .contact-info,
  .contact-form {
    flex: 1 1 100%; /* Full width on small screens */
    max-width: none;
  }
}

@media (max-width: 768px) {
  .input-group input,
  .input-group textarea {
    background-color: rgba(255, 255, 255, 0.8);
  }
}

@media (max-width: 576px) {
  .contact-container {
    padding: 2rem;
  }

  .contact-container-heading{
    font-size: 2.5rem;
  }
  
  .contact-content {
    padding: 1rem;
  }

  .input-group input,
  .input-group textarea {
    padding: 8px;
    font-size: 14px;
  }

  .button-group button {
    padding: 8px 16px;
  }
}
