/* Container for the blog cards */
.blogs-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-image: linear-gradient(to bottom, #e1f2f7, #e6e6e6);
  background-size: cover;
  background-position: center;
  padding-top: 60px;
}

.blog-heading h1 {
  text-align: center;
  font-size: 3.5rem;
  font-weight: bold;
  color: #0f2557;
}

.blog-heading h2 {
  text-align: center;
  font-size: 1.5rem;
  font-weight: bold;
  color: #0f2557;
}

/* All blogs cards */
.blogs-card {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
  border-radius: 12px;
  background-color: #FA255E;
}

/* Individual blog card */
.blog-card {
  position: relative;
  width: 100%;
  max-width: 700px; /* Adjust max-width */
  cursor: pointer;
  overflow: hidden;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.blog-card:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

/* Blog image container */
.blog-image-container {
  position: relative;
  width: 100%;
  height: auto; /* Auto height to maintain aspect ratio */
}

.blog-image {
  width: 100%;
  height: auto;
  object-fit: cover;
  transition: transform 0.3s ease-in-out, filter 0.3s ease-in-out;
}

.blog-card:hover .blog-image {
  transform: scale(1.1);
  filter: blur(4px);
}

/* Blog overlay for title and author */
.blog-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.6);
  color: #fff;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  border-radius: 12px;
}

.blog-card:hover .blog-overlay {
  opacity: 1;
}

/* Blog title */
.blog-title {
  font-size: 1.5em;
  text-align: center;
  color: #FA255E;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.6);
}

/* Blog author */
.blog-author {
  font-size: 1.5em;
  font-weight: bold;
  text-align: center;
  color: #FA255E;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.6);
}

/* Responsive design */
@media (max-width: 768px) {
  .blog-card {
    max-width: 100%;
    height: auto; /* Adjust height for responsiveness */
  }

  .blog-image {
    height: auto; /* Maintain aspect ratio */
  }
}
