/* PrivacyPolicy.css */

html, body {
  height: 100%;
  margin: 0;
}

.policy-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  color: #333;
  font-family: Arial, sans-serif;
  margin: 0 auto;
  max-width: 800px;
}

.policy-container h1 {
  font-size: 2rem;
  margin-bottom: 20px;
  color: #2c3e50;
  text-align: center;
}

.policy-container h2 {
  font-size: 1.5rem;
  margin-top: 20px;
  margin-bottom: 15px;
  color: #34495e;
  border-bottom: 2px solid #e74c3c;
  padding-bottom: 5px;
}

.policy-container p {
  font-size: 1rem;
  margin-bottom: 15px;
  text-align: justify;
  line-height: 1.6;
}

@media (max-width: 600px) {
  .policy-container {
    padding: 15px;
  }

  .policy-container h1 {
    font-size: 1.75rem;
  }

  .policy-container h2 {
    font-size: 1.25rem;
  }

  .policy-container p {
    font-size: 0.9rem;
  }
}
