.policy-container {
    padding: 2rem;
    background-color: #f4f4f4;
    color: #333;
  }
  
  .policy-container h1 {
    color: #0F2557;
    margin-bottom: 1rem;
  }
  
  .policy-container p {
    font-size: 1rem;
    line-height: 1.6;
  }
  