/* Base styles */
.training-page {
  padding: 2rem;
  text-align: center;
  background-image: linear-gradient(to bottom, #e6e6e6 ,#E1F2F7);
  animation: colorChange 5s infinite alternate;
  background-size: 150% auto; 
}

@keyframes colorChange {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}

.training-heading {
  font-size: 3.5rem;
  color: #0F2557;
  margin-bottom: 20px;
}

.mySwiper {
  padding-bottom: 40px;
}

.course-card {
  height: 450px; /* Fixed height for consistency */
  display: flex;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0.5, 0.5, 0.5, 0.8);
  overflow: hidden;
  max-width: 100%;
  margin: 15px auto 30px;
  background-color: #0F2557;
}

.launching-soon-tag,
.not-available-tag {
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 0.9em;
  font-weight: bold;
  color: #fff;
  /* z-index: 10; Ensure tags are above other elements */
}

.launching-soon-tag {
  background-color: #f39c12; /* Orange background for "Launching Soon" */
}

.course-image {
  width: 50%; 
  height: 100%;
  object-fit: cover;
}

.course-details {
  flex: 1;
  padding: 20px;
  text-align: left;
}

.course-title {
  font-size: 1.5em;
  color: #FEE715;
  margin-bottom: 10px;
}

.course-description {
  font-size: 1em;
  color: #FDF5DF;
  margin-bottom: 15px;
}

.course-duration,
.course-price {
  font-size: 1em;
  color: #fff;
  margin-bottom: 5px;
}

.enroll-button {
  text-decoration: none;
  background-color: #EA445A;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 1em;
  font-weight: bold;
  cursor: pointer;
  transition: transform 0.3s;
  display: inline-block; /* Ensure it behaves like a block element */
}

.enroll-button:hover {
  transform: translateY(-3px);
}

.ccl {
  color: #F5B935;
}

/* Example adjustments */
.whatsapp-group {
  margin: 20px 0;
  font-size: 1.1em;
}

.whatsapp-group a {
  color: #007bff;
  text-decoration: none;
}

.whatsapp-group a:hover {
  text-decoration: underline;
}

.launching-soon {
  background-color: #f39c12;
  color: #fff;
  font-weight: bold;
  padding: 5px 10px;
  border-radius: 5px;
  margin-bottom: 10px;
}

.course-not-available {
  color: red;
  font-weight: bold;
  margin-top: 10px;
}

/* Additional adjustments for other elements as needed */

.instructors-heading {
  font-size: 2em;
  margin: 40px 0 20px;
  color: #0F2557;
}

.instructors {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  margin-bottom: 20px;
}

.instructor-card {
  backdrop-filter: blur(10px);
  background-color: #0F2557;
  border-radius: 10px;
  box-shadow: 2px 4px 8px rgba(0.5, 0.5, 0.5, 0.8);
  overflow: hidden;
  transition: transform 0.3s, background 0.3s;
  width: 250px;
  padding: 15px;
  color: white;
  margin: 0 20px;
}

.instructor-card:hover {
  transform: scale(1.05);
  background: #001730;
}

.instructor-image {
  border-radius: 50%;
  width: 150px;
  height: 150px;
  object-fit: cover;
  margin-bottom: 1rem;
  transition: transform 0.3s;
}

.instructor-name {
  font-size: 1.2em;
  margin: 15px 0 10px;
  color: #fff;
  font-weight: bold;
}

.instructor-course {
  font-size: 1em;
  margin: 10px 0;
  color: #fff;
}

.linkedin-link {
  text-decoration: none;
  background-color: #0e76a8;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 1em;
  font-weight: bold;
  cursor: pointer;
  transition: transform 0.3s;
  display: inline-block;
  margin-top: 10px;
}

.linkedin-link:hover {
  transform: translateY(-3px);
}

/* Responsive adjustments for medium screens */
@media (max-width: 1024px) {
  .training-heading {
    font-size: 2em;
  }

  .course-card {
    max-width: 70%;
    height: auto;
    flex-direction: column;
  }

  .course-image {
    width: 100%;
    height: auto;
  }
}

/* Responsive adjustments for small screens */
@media (max-width: 768px) {
  .training-page {
    padding: 1rem;
  }

  .training-heading {
    font-size: 1.8em;
    margin-bottom: 10px;
  }

  .course-card {
    max-width: 90%;
    height: auto;
    flex-direction: column;
  }

  .course-image {
    width: 100%;
    height: auto;
    max-height: 300px;
  }

  .course-details {
    padding: 20px;
    text-align: center;
  }

  .instructor-card {
    width: 80%;
  }

  .instructors-heading {
    font-size: 1.5em;
  }
}

/* Responsive adjustments for extra small screens */
@media (max-width: 480px) {
  .training-page {
    padding: 0.5rem;
  }

  .training-heading {
    font-size: 1.5em;
  }

  .course-card {
    max-width: 90%;
  }

  .course-details {
    padding: 10px;
  }

  .instructor-image {
    width: 130px;
    height: 130px;
    object-fit: cover;
    margin-bottom: 0.2rem;
    transition: transform 0.3s;
  }

  .instructor-card {
    width: 50%;
  }

  .instructors-heading {
    font-size: 1.2em;
  }
}
